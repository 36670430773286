import "./sections.css"

import React from "react"

import { Link } from "gatsby"

const Section1 = () => {

    return <section className="donate-sec-1">
        <div className="content">
            <h2>What would you like to do?</h2>
        </div>
    </section>
}
const Tab1 = ({ onDonate }) => {
    return <section className="donate-tabs donate-tab-1">
        <div className='content'>
            <h3>One-Time Donation</h3>
            <div className="inner-content">
                <div className='content-left'>
                    <p>Make a one time online donation - you choose the amount. It’s secure, it’s fast, and it’s free - just click “Donate”!</p>
                    <button onClick={() => { onDonate() }} className='btn-accent'>Donate</button>
                </div>
                <div className='content-right'>
                    <h4>Or to donate cash, you can mail to:</h4>
                    <p><strong>Dallas | Fort Worth Labrador Retriever Rescue Club, Inc.</strong><br />
                        P.O. Box 94002<br />
                        Southlake, TX 76092</p>

                    <p>Stock Donations, please contact <a href="mailto:treasurer@dfwlabrescue.org">treasurer@dfwlabrescue.org</a></p>
                </div>
            </div>
        </div>
    </section>
}
const Tab2 = ({ data, onDonate }) => {
    return <section className="donate-tabs donate-tab-2">
        <div className='content'>
            <h3>Recurring Donation</h3>
            <p>Set up a recurring donation on online. It's secure, fast, free and effortless - start by clicking "Donate"!</p>
            <div className="items">
                {data && data.map((item, key) => {
                    return item.donation_type.key === 'recurring' && <div key={key} className='item'>
                        <h4>${item.amount} each month:</h4>
                        <button onClick={() => { onDonate(item._id) }} className='btn-accent'>Donate</button>
                    </div>
                })}
            </div>
        </div>
    </section>
}

const Tab3 = ({ data,onDonate }) => {
    return <section className="donate-tabs donate-tab-3">
        <div className='content'>
            <h3>Sponsor a Dog</h3>
            <p>To sponsor a dog, select a level of sponsorship. When checking out, in the comments field please specify the dog you wish to sponsor.</p>
            <div className="items">
                {data && data.map((item, key) => {
                    return item.donation_type.key === 'sponsor-a-dog' && <div key={key} className='item'>
                        <h4>${item.amount} {item.name}</h4>
                        <p>{item.description}</p>
                        <button onClick={() => { onDonate(item._id) }} className='btn-accent'>Donate</button>
                    </div>
                })}
            </div>
        </div>
    </section>
}
const Tab4 = ({ data,onDonate }) => {
    return <section className="donate-tabs donate-tab-4">
        <div className='content'>
            <h3>Gifting</h3>
            <div className="memorial-list">
                {data && data.map((item, key) => {
                    return item.donation_type.key === 'memorial-gifting' && <div key={key} className='item'>
                        <h4>{item.name}</h4>
                        {item.description !== "" && <p>{item.description}</p>}

                        <button onClick={() => { onDonate(item._id) }} className='btn-accent'>Donate</button>
                    </div>
                })}
            </div>
        </div>
    </section>
}
const Tab5 = () => {
    return <section className="donate-tabs donate-tab-5">
        <div className='content'>
            <h3>Fundraising</h3>
            <h4>NON-MONETARY CONTRIBUTIONS</h4>
            <h5>Amazon Wish List</h5>
            <p>Looking for ways to donate items to DFW Lab Rescue? Would you like to host your own fundraiser at school, the office, at church, for your troop, or for a child's Birthday Party? Then, check out our <Link target="_blank" to="http://smile.amazon.com/registry/wishlist/1IYE6IKKZB63">Amazon Wish List</Link> for items we need.</p>
            <h5>Amazon Smile</h5>
            <p>Sign up for Amazon Smile! This is a simple and automatic way for you to support your favorite charitable organization every time you shop. Amazon will donate 0.5% of your purchase price to the organization of your choosing. Find us as "Dallas Fort Worth Labrador Retriever Rescue Club Inc".</p>
            <h5>Kroger and Tom Thumb Reward Cards</h5>
            <p>Consider adding Dallas-Fort Worth Labrador Retriever Rescue to your Kroger and Tom Thumb Rewards cards. For every trip to the store and swipe of your card, we earn donations to help save more Labs!</p>
            <h5>Track your Walks</h5>
            <p>You walk your dogs, now do it with a purpose! Download the WoofTrax app, designate DFW Lab Rescue as your charity and just take your phone with you to track your walk. They will donate for all miles walked. Check it out at <Link target="_blank" to="http://www.wooftrax.com/">WoofTrax</Link></p>
            <h5>Employer Matching</h5>
            <p>Check with your employer to see if they have a matching donations program. Many Dallas Ft. Worth area employers offer this benefit and we are beyond grateful for the regular donations that come in. No amount is too small to make a difference. </p>
        </div>
    </section>
}
const Sections = {
    Section1,
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5
}

export default Sections