import React, { useState } from "react"
import "./donate-tabs.css"
const TabItem = ({ children, isActive, className, label, onClick }) => {


    return <>
        <div className="tab-button">
            <button className={className + " " + (isActive ? "active" : "")} onClick={onClick}>
                <span className="icon"></span>
                <span className="lbl">{label}</span></button>
        </div>
        <div className={"tab-content " + (isActive ? "active" : "")}>
            {children}
        </div>
    </>
}
const DonateTabs = ({ children }) => {
    const [active, setActive] = useState(0);

    return <div className="donate-tabs">
        <ul className="tabs-menu">
            {children && children.map((child, key) => {
                return <li key={key} className={active === key ? "active" : ""}><button className={child.props.className} onClick={() => {
                    setActive(key);
                }}>
                    <span className="icon"></span>
                    <span className="lbl">{child.props.label}</span></button></li>
            })}
        </ul>

        {children && children.map((child, key) => {
            return <TabItem index={key} className={child.props.className} onClick={() => {
                setActive(key);
              
                setTimeout(()=>{
                    window.scroll({ top: (document.querySelector(".tab-button .active").offsetTop - 100), behavior: 'smooth' });
                },100);
            }} label={child.props.label} isActive={active === key}>
                {child.props.children}
            </TabItem>
        })}
    </div>
}

export default DonateTabs