import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerBanner from "../components/shared/inner-banner"
import banner from "../images/donate-banner-bg.jpg"
import Sections from "../components/donate/sections"
import DonateTabs from "../components/shared/donate-tabs"
import { API_BASE_URL } from "../constants/config"
import axios from "axios"
import DonateLightbox from "../components/shared/donate-lightbox"

const DonatePage = () => {
  const [data, setData] = useState(null);
  const [donateLightbox, setDonateLightbox] = useState({ show: false, donation_type: "one-time", donation_item: null })

  useEffect(() => {
    var m = true;
    if (m) {
      axios.get(API_BASE_URL + "/website/donate/donation-items").then(({ data }) => {
        setData(data.data);
      })
    }
    return () => {
      m = false;
    }

  }, [])

  const onDonate = (type, id) => {

    setDonateLightbox(state => ({ ...state, show: true, donation_type: type, donation_item: id }));
    document.querySelector("body").className = 'overflow-hidden';
  }
  const onCloseLightbox = () => {
    setDonateLightbox(state => ({ ...state, show: false, donation_type: "", donation_item: "" }));
    document.querySelector("body").className = '';
  }


  return <Layout colored_header={true}>
    <Seo title="Donate" />
    <InnerBanner theme="donate-banner white" image={banner} title="Donate" content={`
    <h2>Thank you for considering a donation to Dallas | Fort Worth Labrador Retriever Rescue Club, Inc. (DFWLRRC).</h2>
    <p>Our rescue Labs and volunteers appreciate your interest in supporting this very special cause. Dallas | Fort Worth Labrador Retriever Rescue Club, incorporated in the state of Texas, is a non-profit, charitable 501(c)(3) organization. All contributions to DFWLRRC are tax deductible to the full extent of the law.</p>
    <p>All donations to DFWLRRC are acknowledged in writing. If your contribution is in honor of or in memory of an individual, or to commemorate a special occasion, an acknowledgement card will be sent to you or to anyone you designate. (Please note that all acknowledgments are written by DFWLRRC volunteers. Depending on their availability, it may take a few weeks for you or your designee to receive the card.)</p>
    `} />

    <Sections.Section1 />
    <DonateTabs>
      <div className="one-time" label="One-time donation">
        <Sections.Tab1 onDonate={() => { onDonate('one-time', '') }} />
      </div>
      <div className="recurring" label="Recurring donation">
        <Sections.Tab2 data={data} onDonate={(id) => { onDonate('recurring', id) }} />
      </div>
      <div className="sponsor" label="Sponsor a Dog">
        <Sections.Tab3 data={data} onDonate={(id) => { onDonate('sponsor-a-dog', id) }} />
      </div>
      <div className="memorial" label="Gifting">
        <Sections.Tab4 data={data} onDonate={(id) => { onDonate('memorial-gifting', id) }} />
      </div>
      <div className="fundraising" label="Fundraising">
        <Sections.Tab5 />
      </div>
    </DonateTabs>
    <DonateLightbox onClose={onCloseLightbox} donation_item={donateLightbox.donation_item} show={donateLightbox.show} donation_type={donateLightbox.donation_type} donation_items={data} />

  </Layout>
}

export default DonatePage